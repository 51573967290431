import React from 'react';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';
import cn from 'classnames';

import styles from './support-chat-button.module.css';
import { useAppStore } from '../../store/app';
import { TelegramIcon } from '../../ui/icons/telegram/telegram';
import { Button } from '../../ui/button/button';

type SupportChatButtonVariant = 'default' | 'accent';

type SupportChatButtonProps = {
  variant: SupportChatButtonVariant;
};

export const SupportChatButton = observer<SupportChatButtonProps>(
  ({ variant }) => {
    const { telegramSupportLink } = useAppStore();
    return (
      <a
        className={styles.link}
        href={telegramSupportLink}
        target="_blank"
        rel="noreferrer"
      >
        <Button className={cn(styles.button, styles[variant])}>
          <span className={styles.buttonContent}>
            <TelegramIcon />
            <span className={styles.buttonText}>{t('footer.support')}</span>
          </span>
        </Button>
      </a>
    );
  }
);
