import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './error.module.css';
// TODO: add icons with type
import calendar from './calendar.svg';

type Props = {
  title?: string;
  message?: string;
  errorMessage?: ReactNode;
  skipHeader?: boolean;
};

export const ErrorBlock: FC<Props> = ({
  message,
  title,
  errorMessage,
  skipHeader,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.errorContainer}>
      <img className={styles.calendar} src={calendar} />
      {!skipHeader && <h2>{title || t('error.title')}</h2>}
      <p>{message || t('error.unknown')}</p>
      {errorMessage && (
        <div className={styles.errorMessage}>
          <div className={styles.errorMessageBody}>{errorMessage}</div>
        </div>
      )}
    </div>
  );
};
