import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import styles from './footer.module.css';
import logoWhiteImg from '../../assets/logo.svg';
import logoBlackImg from '../../assets/logo-alt.svg';
import { TelegramIcon } from '../../ui/icons/telegram/telegram';
import { VkIcon } from '../../ui/icons/vk/vk';
import { InstagramIcon } from '../../ui/icons/instagram/instagram';
import { INSTAGRAM_LINK, QH, TELEGRAM_LINK, VK_LINK } from '../../constants';
import { SupportChatButton } from '../support-chat-button/support-chat-button';

type FooterProps = {
  isSocialsVisible?: boolean;
  isLogoVisible?: boolean;
  isDisclaimerVisible?: boolean;
  isSupportVisible?: boolean;
};

export const Footer: FC<FooterProps> = ({
  isSocialsVisible,
  isDisclaimerVisible,
  isSupportVisible,
}) => {
  const { t } = useTranslation();
  if (!isSupportVisible) {
    return (
      <div className={styles.whiteFooter}>
        <div className={styles.container}>
          <div className={styles.logo}>
            <img className={styles.logo} src={logoBlackImg} alt={QH} />
          </div>
          <div className={styles.copy}>&copy; 2024</div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles.chat}>
        {isSupportVisible && <SupportChatButton variant="default" />}
        {isDisclaimerVisible && (
          <div className={styles.chatText}>
            <Trans>{t('footer.disclaimer')}</Trans>
          </div>
        )}
      </div>
      {isSocialsVisible && (
        <div className={styles.socials}>
          <ul className={styles.socialsList}>
            <li>
              <a href={TELEGRAM_LINK} target="_blank" rel="noreferrer">
                <TelegramIcon width={20} height={16} />
                <span className={styles.socialsListText}>Telegram</span>
              </a>
            </li>
            <li>
              <a href={VK_LINK} target="_blank" rel="noreferrer">
                <VkIcon />
                <span className={styles.socialsListText}>Vkontakte</span>
              </a>
            </li>
            <li>
              <a href={INSTAGRAM_LINK} target="_blank" rel="noreferrer">
                <InstagramIcon />
                <span className={styles.socialsListText}>Instagram</span>
              </a>
            </li>
          </ul>
        </div>
      )}
      <div className={styles.copy}>
        &copy; 2024 <img className={styles.logo} src={logoWhiteImg} alt={QH} />
      </div>
    </div>
  );
};
